/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import Swal from "sweetalert2";
import axios from "axios";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Row,
  Button,
  Col,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import { BiDotsVertical } from "react-icons/bi";
// core components
import Header from "components/Headers/Header.js";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
const JobIndex = () => {
  const history = useHistory();
  var arrayForDeletion = [];
  const [newData, setNewData] = useState([]);

  const [toggle, settoggle] = useState(false);
  const [toggleEdit, settoggleEdit] = useState(false);

  const handleToggle = () => {
    settoggle(!toggle);
  };

  const [data, setData] = useState({
    title: "",
    description: "",
    experience: "",
    qualification: "",
    location: "",
    openings: "",
  });

  const handleOnSubmit = (e) => {
    e.preventDefault();

    const jobData = {
      id: data._id,
      title: data.title,
      description: data.description,
      experience: data.experience,
      qualification: data.qualification,
      location: data.location,
      openings: data.openings,
    };
    axios
      .post("https://backend.tror.ai/api/job/new", jobData)
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: "Success",
          type: "success",
          text: `Job ${data.title} has been created`,
        }).then((result) => {
          if (result.value) {
            fetchJobs();
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });

    setData({
      title: "",
      description: "",
      experience: "",
      qualification: "",
      location: "",
      openings: "",
    });
  };

  //fetching api data
  const [jobs, setJobs] = useState([]);
  const fetchJobs = () => {
    axios
      .get("https://backend.tror.ai/api/jobs")
      .then((res) => {
        setJobs(res.data.jobs);
        setNewData(res.data.jobs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  //update job
  // const [update, setUpdate] = useState(false);
  const [updateData, setUpdateData] = useState({
    title: "",
    description: "",
    experience: "",
    qualification: "",
    location: "",
    openings: "",
  });

  const handleUpdate = (id) => {
    axios
      .get(`https://backend.tror.ai/api/job/${id}`)
      .then((res) => {
        setUpdateData(res.data.job);
        console.log(res.data.job);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const noDataToDelete = () => {
    Swal.fire({
      title: "No data to delete",
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
    });
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    console.log(updateData, "before");
    const jobData = {
      title: updateData.title,
      description: updateData.description,
      experience: updateData.experience,
      qualification: updateData.qualification,
      location: updateData.location,
      openings: updateData.openings,
    };
    axios
      .put(`https://backend.tror.ai/api/job/${updateData._id}`, jobData)
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: "Success",
          type: "success",
          text: `Profile ${updateData.title} has been successfully updated`,
        }).then((result) => {
          if (result.value) {
            fetchJobs();
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
    settoggleEdit(!toggleEdit);
    setUpdateData({
      title: "",
      description: "",
      experience: "",
      qualification: "",
      location: "",
      openings: "",
    });
  };

  //delete job
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
    })
      .then((result) => {
        if (result.value) {
          axios
            .delete(`https://backend.tror.ai/api/job/${id}`)
            .then((res) => {
              Swal.fire({
                title: "Deleted!",
                text: "Job Profile has been deleted.",
                type: "success",
              });
              fetchJobs();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteAllJobs = () => {
    Swal.fire({
      title: "Are you sure to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
    })
      .then((result) => {
        if (result.value) {
          axios
            .delete("https://backend.tror.ai/api/deletealljobs")
            .then((res) => {
              Swal.fire({
                title: "Deleted!",
                text: "All Job Profiles have been deleted.",
                type: "success",
              });
              fetchJobs();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header />
      {/* Page content */}
      {toggle ? (
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Add New job</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        // href="/blogs"
                        onClick={handleToggle}
                        size="sm"
                      >
                        Back to list
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleOnSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Profile
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="Job profile"
                              name="title"
                              value={data.title}
                              type="text"
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    title: e.target.value,
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Experience
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="Experience"
                              name="experience"
                              type="text"
                              value={data.experience}
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    experience: e.target.value,
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Description
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Write description of job profile here"
                              rows="4"
                              value={data.description}
                              name="description"
                              type="textarea"
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    description: e.target.value,
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Qualification
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              name="qualification"
                              value={data.qualification}
                              placeholder="Qualification"
                              type="text"
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    qualification: e.target.value,
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Location
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="Location"
                              value={data.location}
                              name="location"
                              type="text"
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    location: e.target.value,
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Openings
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="Number of Openings"
                              type="text"
                              value={data.openings}
                              name="openings"
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    openings: e.target.value,
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <div className="text-right">
                      <Button color="primary" type="submit">
                        Save
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : toggleEdit ? (
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Edit Blog</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        // href="/blogs"
                        onClick={(e) => {
                          settoggleEdit(!toggleEdit);
                        }}
                        size="sm"
                      >
                        Back to list
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleUpdateSubmit}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Profile
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="Job profile"
                              name="title"
                              value={updateData.title}
                              type="text"
                              onChange={(e) => {
                                setUpdateData((prevState) => {
                                  return {
                                    ...prevState,
                                    title: e.target.value,
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Experience
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="Experience"
                              name="experience"
                              type="text"
                              value={updateData.experience}
                              onChange={(e) => {
                                setUpdateData((prevState) => {
                                  return {
                                    ...prevState,
                                    experience: e.target.value,
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Description
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Write description of job profile here"
                              rows="4"
                              value={updateData.description}
                              name="description"
                              type="textarea"
                              onChange={(e) => {
                                setUpdateData((prevState) => {
                                  return {
                                    ...prevState,
                                    description: e.target.value,
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Qualification
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              name="qualification"
                              value={updateData.qualification}
                              placeholder="Qualification"
                              type="text"
                              onChange={(e) => {
                                setUpdateData((prevState) => {
                                  return {
                                    ...prevState,
                                    qualification: e.target.value,
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Location
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="Location"
                              value={updateData.location}
                              name="location"
                              type="text"
                              onChange={(e) => {
                                setUpdateData((prevState) => {
                                  return {
                                    ...prevState,
                                    location: e.target.value,
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Openings
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="Number of Openings"
                              type="text"
                              value={updateData.openings}
                              name="openings"
                              onChange={(e) => {
                                setUpdateData((prevState) => {
                                  return {
                                    ...prevState,
                                    openings: e.target.value,
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <div className="text-right">
                      <Button color="primary" type="submit">
                        Update
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="5">
                      <h3 className="mb-0">Jobs</h3>
                    </Col>
                    <Col className="text-center" xs="2">
                      <Input
                        type="text"
                        placeholder="Search..."
                        style={{
                          height: "28px",
                          width: "300px",
                          position: "relative",
                          right: "35%",
                        }}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setJobs(newData);
                          } else {
                            const search = e.target.value;

                            const filtered = newData.filter((job) => {
                              return (
                                job.title
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ||
                                job.description
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ||
                                job.location
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ||
                                job.qualification
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ||
                                job.experience
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ||
                                job.openings
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              );
                            });

                            setJobs(filtered);
                          }
                        }}
                      />
                    </Col>
                    <Col className="text-right" xs="5">
                      <Button
                        style={{
                          backgroundColor: "#ff0000",
                          color: "#fff",
                          // position: "relative",
                          // left: "20%",
                          cursor: "pointer",
                        }}
                        size="sm"
                        onClick={(e) => {
                          e.preventDefault();
                          // fetch(
                          //   // "https://backend.tror.ai/api/deleteMultipJobs",
                          //   "http://localhost:80/api/deleteMultipJobs",
                          //   {
                          //     method: "DELETE",
                          //     headers: {
                          //       "Content-Type": "application/json",
                          //     },
                          //     body: JSON.stringify({
                          //       jobids: arrayForDeletion,
                          //     }),
                          //   }
                          // ).then((res) => {
                            Swal.fire({
                              title: `${arrayForDeletion.length === 0 ? "No item selected" : "Are you sure?"}`,
                              text: `${arrayForDeletion.length === 0 ? "Please select any one item before deleting" : "You won't be able to revert this!"}`, 
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                            }).then((result) => {
                              if(result.value){
                                fetch(
                                "https://backend.tror.ai/api/deleteMultipJobs",
                                  
                            {
                              method: "DELETE",
                              headers: {
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({
                                jobids: arrayForDeletion,
                              }),
                            }
                                )
                                fetchJobs()
                              }
                              document
                                .querySelectorAll("input[type=checkbox]")
                                .forEach((element) => {
                                  element.checked = false;
                                });

                              arrayForDeletion = [];
                              fetchJobs();
                            });
                          // });
                        }}
                      >
                        Delete Checked Row
                      </Button>
                      <Button
                        color="primary"
                        onClick={handleToggle}
                        size="sm"
                        style={
                          {
                            // position: "relative",
                            // left: "20%",
                          }
                        }
                      >
                        Add New
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">ID</th>
                      <th scope="col">Profile</th>
                      <th scope="col">Description</th>
                      <th scope="col">Experience</th>
                      <th scope="col">qualification</th>
                      <th scope="col">location</th>
                      <th scope="col">openings</th>

                      <th scope="col" />
                    </tr>
                  </thead>
                  {jobs.length > 0 ? (
                    <tbody>
                      {jobs.map((job) => (
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              style={{
                                width: "px",
                                height: "20px",
                              }}
                              id="CheckAllZ"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  arrayForDeletion.push(job._id);
                                } else {
                                  arrayForDeletion.splice(
                                    arrayForDeletion.indexOf(job._id),
                                    1
                                  );
                                }
                              }}
                            />
                          </td>
                          <td>{job._id}</td>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {job.title}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td className="text-truncate" style={{maxWidth:"500px"}}>{job.description}</td>
                          <td>{job.experience}</td>
                          <td className="text-truncate" style={{maxWidth:"500px"}}>{job.qualification}</td>
                          <td>{job.location}</td>
                          <td>{job.openings}</td>

                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-dark"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <BiDotsVertical />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => {
                                    settoggleEdit(!toggleEdit);
                                    handleUpdate(job._id);
                                  }}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => {
                                    handleDelete(job._id);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="7">No Jobs Found.</td>
                      </tr>
                    </tbody>
                  )}
                </Table>

                {/* <Button
                  className="primary"
                  style={{
                    backgroundColor: "#5E72E4",
                    color: "white",
                    marginTop: "20px",
                  }}
                  onClick={data.length > 0 ? deleteAllJobs : noDataToDelete}
                >
                  Delete All
                </Button> */}
              </Card>
            </div>
          </Row>
        </Container>
      )}
    </>
  );
};

export default JobIndex;
