/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import UserHeader from "components/Headers/UserHeader.js";

import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const Profile = () => {
  const [getUser, setGetUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const [pass, setPass] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [edit, setEdit] = useState(false); //edit button

  const data = JSON.parse(localStorage.getItem("adminData"));

  const token = data.authtoken;

  const handleGetUser = async () => {
    const res = await axios.get("https://backend.tror.ai/api/getUser", {
      headers: {
        authToken: `${token}`,
      },
    });
    setGetUser(res.data.user);
  };

  console.log(getUser, "getUser");
  const handlePass = async () => {
    if (
      pass.currentPassword === "" ||
      pass.newPassword === "" ||
      pass.confirmPassword === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill all the fields",
      });
    } else if (pass.newPassword != pass.confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "New password and confirm password does not match!",
      });
    } else {
      const res = await axios
        .put(
          `https://backend.tror.ai/api/changePassword/${getUser._id}`,
          pass,
          {
            headers: {
              authToken: `${token}`,
            },
          }
        )
        .then(
          Swal.fire({
            title: "Password Changed",
            icon: "success",
          })
        );
      console.log(res);
    }
  };

  console.log(getUser, "getUser");
  useEffect(() => {
    handleGetUser();
  }, []);
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h6 className="heading-small text-muted mb-4">
                        User information
                      </h6>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="default"
                        href="#pablo"
                        onClick={() => {
                          if (edit === false) {
                            //alert for edit enable

                            Swal.fire({
                              position: "top-end",
                              icon: "success",
                              title: "Edit Profile Enabled",
                              text: "Click on the fields to edit",
                              showConfirmButton: false,
                              timer: 1500,
                            });
                          }
                          setEdit(!edit);
                        }}
                        size="sm"
                      >
                        Edit Profile
                      </Button>
                    </Col>
                  </Row>
                  {/*Button for edit profile*/}
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            First Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            defaultValue={getUser.firstName}
                            placeholder=""
                            {...(!edit && { disabled: true })}
                            type="text"
                            onChange={
                              edit &&
                              ((e) => {
                                setGetUser({
                                  ...getUser,
                                  firstName: e.target.value,
                                });
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Last Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            defaultValue={getUser.lastName}
                            placeholder=""
                            {...(!edit && { disabled: true })}
                            type="text"
                            onChange={
                              edit &&
                              ((e) => {
                                setGetUser({
                                  ...getUser,
                                  lastName: e.target.value,
                                });
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Phone Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            type="text"
                            defaultValue={getUser.phoneNumber}
                            {...(!edit && { disabled: true })}
                            onChange={
                              edit &&
                              ((e) => {
                                setGetUser({
                                  ...getUser,
                                  phoneNumber: e.target.value,
                                });
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            type="text"
                            defaultValue={getUser.email}
                            {...(!edit && { disabled: true })}
                            onChange={
                              edit &&
                              ((e) => {
                                setGetUser({
                                  ...getUser,
                                  email: e.target.value,
                                });
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3"></Col>
                      <Col lg="6">
                        <FormGroup>
                          <Button
                            className="btn-block"
                            color="primary"
                            type="button"
                            {...(!edit && { style: { display: "none" } })}
                            onClick={() => {
                              axios
                                .put(
                                  `https://backend.tror.ai/api/editUser/${getUser._id}`,
                                  getUser,
                                  {
                                    headers: {
                                      authToken: `${token}`,
                                    },
                                  }
                                )
                                .then((res) => {
                                  Swal.fire({
                                    title: "Profile Updated Successfully",
                                    icon: "success",
                                  }).then(() => {
                                    setEdit(false);
                                  });
                                })
                                .catch((err) => {
                                  console.log(err);
                                });
                            }}
                          >
                            Save Changes
                          </Button>
                        </FormGroup>
                      </Col>
                      <Col lg="3"></Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                </Form>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Change Password
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="7">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Current Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Enter your current password here"
                            type="password"
                            value={pass.currentPassword}
                            onChange={(e) =>
                              setPass({
                                ...pass,
                                currentPassword: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="7">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            New Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="Enter New Password here"
                            type="password"
                            value={pass.newPassword}
                            onChange={(e) =>
                              setPass({
                                ...pass,
                                newPassword: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="7">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Confirm Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="Confirm your new password here"
                            type="password"
                            value={pass.confirmPassword}
                            onChange={(e) =>
                              setPass({
                                ...pass,
                                confirmPassword: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Button
                            color="primary"
                            //button type submit
                            // type="submit"
                            onClick={handlePass}
                          >
                            Change Password
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
