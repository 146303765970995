/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { useEffect, useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import "./Sidebar.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { CgProfile } from "react-icons/cg";
import { FcBusinessman } from "react-icons/fc";
import { FcList } from "react-icons/fc";
// import { IoLogOut } from "react-icons/io";
import { HiOutlineLogout } from "react-icons/hi";
// import { IoLogOut } from "react-icons/io";
import {
  FcBusinessContact,
  FcBriefcase,
  FcReading,
  FcConferenceCall,
  FcRules,
  FcStackOfPhotos,
} from "react-icons/fc";

// var ps;

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  const history = useHistory();
  const [getUser, setGetUser] = useState({
    firstName: "",
    lastName: "",
  });

  const data = JSON.parse(localStorage.getItem("adminData"));

  const token = data.authtoken;

  const handleLogout = () => {
    localStorage.removeItem("adminData");
    history.push("/auth/login");
  }; 

  const handleGetUser = async () => {
    const res = await axios.get("https://backend.tror.ai/api/getUser", {
      headers: {
        authToken: `${token}`,
      },
    });
    setGetUser(res.data.user);
  };

  useEffect(() => {
    handleGetUser();
  }, []);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  const closeCollapseForLogout = () => {
    setCollapseOpen(false);
    handleLogout();
  };
  // creates the links that appear in the left menu / Sidebar

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <div>
          {prop.path == "/login" ? (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapseForLogout}
                activeClassName="active"
              >
                <span style={{ marginRight: "20px" }}>
                  <HiOutlineLogout />
                </span>
                {prop.name}
              </NavLink>
            </NavItem>
          ) : prop.path == "/index" ? (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
              >
                <span style={{ marginRight: "20px" }}>
                  <FcList />
                </span>
                {prop.name}
              </NavLink>
            </NavItem>
          ) : prop.path == "/contactForm" ? (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
              >
                <span style={{ marginRight: "20px" }}>
                  <FcBusinessContact />
                </span>
                {prop.name}
              </NavLink>
            </NavItem>
          ) : prop.path == "/user-profile" ? (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
              >
                <span style={{ marginRight: "20px" }}>
                  <FcBusinessman />
                </span>
                {prop.name}
              </NavLink>
            </NavItem>
          ) : prop.path == "/jobs" ? (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
              >
                <span style={{ marginRight: "20px" }}>
                  <FcBriefcase />
                </span>
                {prop.name}
              </NavLink>
            </NavItem>
          ) : prop.path == "/articles" ? (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
              >
                <span style={{ marginRight: "20px" }}>
                  <FcStackOfPhotos />
                </span>
                {prop.name}
              </NavLink>
            </NavItem>
          ) : prop.path == "/jobApplication" ? (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
              >
                <span style={{ marginRight: "20px" }}>
                  <FcConferenceCall />
                </span>
                {prop.name}
              </NavLink>
            </NavItem>
          ) : (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
              >
                <i className={prop.icon} />
                {prop.name}
              </NavLink>
            </NavItem>
          )}
        </div>
      );
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white nl"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid className="nl-1">
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand to="/" tag={Link} className="nl-2">
            <img
              className="navbar-brand-img"
              style={{
                transform: "scale(1.5)",
              }}
              alt="..."
              src={
                require("../../assets/img/theme/tror_black_large.png").default
              }
            />
          </NavbarBrand>
        ) : null}
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav className="nav-link-icon">
              {getUser.firstName + " " + getUser.lastName}
            </DropdownToggle>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <CgProfile />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem href="#pablo" onClick={handleLogout}>
                <a
                  href="https://www.flaticon.com/free-icons/logout"
                  title="logout icons"
                >
                  Logout
                </a>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}

          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
          {/* Divider */}
          <hr className="my-3" />
          {/* Heading */}
          {/* <h6 className="navbar-heading text-muted">Documentation</h6> */}
          {/* Navigation */}
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
