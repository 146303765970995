/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import Swal from "sweetalert2";
import {
  Badge,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Button,
  Col,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  UncontrolledTooltip,
  Dropdown,
} from "reactstrap";
import { BiDotsVertical } from "react-icons/bi";
import { CSVLink } from "react-csv";
// core components
import Header from "components/Headers/Header.js";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

// core components

const JobApplications = () => {
  var arrayForDeletion = [];
  const [lengthDeleteArray, setLengthDeleteArray] = useState(0)
  //dropdown toggle
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  //fetching api data
  const [applications, setApplications] = useState([]);
  const [filterApplication, setFilterApplication] = useState([]);
  const [newData, setNewData] = useState([]);
  const [allApplication, setAllApplication] = useState([])
  

  const myData = applications.map((item) => {
    return {
      name: item.name,
      jobProfile: item.jobProfile,
      email: item.email,
      phoneNumber: item.phoneNumber,
      experience: item.experience,
      file: item.file,
    };
  });

  const noDataToDelete = () => {
    Swal.fire({
      title: "No data to delete",
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
    });
  };

  const headers = [
    { label: "Name", key: "name" },
    { label: "Profile", key: "jobProfile" },
    { label: "Email", key: "email" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Experience", key: "experience" },
    { label: "Resume", key: "file" },
  ];

  const csvReport = {
    filename: "JobApplications.csv",
    headers: headers,
    data: myData,
  };

  const fetchApplications = () => {
    axios
      .get("https://backend.tror.ai/api/getAllAplications")
      .then((res) => {
        setApplications(res.data.result);
        setFilterApplication(res.data.result);
        setNewData(res.data.result);
        setAllApplication(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterJobProfile = (profile) => {
    if (profile === "All") {
      fetchApplications();
    } else {
      let filterData = allApplication.filter((item) => {
        return item.jobProfile === profile;
      });
      setApplications(filterData);
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`https://backend.tror.ai/api/deleteApplication/${id}`)
          .then((res) => {
            fetchApplications();
            Swal.fire("Deleted!", "Application has been deleted.", "success");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const deleteAll = () => {
    Swal.fire({
      title: "Are you sure to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        axios
          .delete("https://backend.tror.ai/deleteAllapplications")
          .then((res) => {
            fetchApplications();
            Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  useEffect(() => {
    fetchApplications();


    setLengthDeleteArray(arrayForDeletion.length)
  }, []);


console.log(applications);
console.log(lengthDeleteArray)

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="5">
                    <h3 className="mb-0">Job Applications</h3>
                  </Col>
                  <Col className="text-center" xs="2">
                    <Input
                      type="text"
                      placeholder="Search..."
                      style={{
                        height: "28px",
                        width: "300px",
                        position: "relative",
                        right: "35%",
                      }}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setApplications(newData);
                        } else {
                          const search = e.target.value;

                          const filtered = newData.filter((application) => {
                            return (
                              // application.jobProfile
                              //   .toLowerCase()
                              //   .includes(search.toLowerCase()) ||
                              application.phoneNumber
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                              application.email
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                              application.name
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                              application.experience
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            );
                          });

                          setApplications(filtered);
                        }
                      }}
                    />
                  </Col>
                  {/*DropDown for Job profile wise filter*/}

                  <Col className="text-right">
                    <Button
                      style={{
                        backgroundColor: "#ff0000",
                        color: "#fff",
                        // position: "relative",
                        // left: "60%",
                        cursor: "pointer",
                        // minWidth: "150px",
                      }}
                      size="sm"
                      onClick={(e) => {
                        e.preventDefault();
                        // fetch(
                        //   // "https://backend.tror.ai/api/deleteMultipleApplications",
                        //   "http://localhost:80/api/deleteMultipleApplications",
                        //   {
                        //     method: "DELETE",
                        //     headers: {
                        //       "Content-Type": "application/json",
                        //     },
                        //     body: JSON.stringify({
                        //       applicationIds: arrayForDeletion,
                        //     }),
                        //   }
                        // ).then((res) => {
                          Swal.fire({
                            title: `${arrayForDeletion.length === 0 ? "No item selected" : "Are you sure?"}`,
                              text: `${arrayForDeletion.length === 0 ? "Please select any one item before deleting" : "You won't be able to revert this!"}`,
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                          }).then((result) => {
                            if(result.value){
                              fetch(

                                "https://backend.tror.ai/api/deleteMultipleApplications",
                                {
                                  method: "DELETE",
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                  body: JSON.stringify({
                                    applicationIds: arrayForDeletion,
                                  }),
                                }
                              )
                            fetchApplications();
                            }
                            document
                              .querySelectorAll("input[type=checkbox]")
                              .forEach((element) => {
                                element.checked = false;
                              });

                            arrayForDeletion = [];
                            fetchApplications();
                          });
                        // });
                      }}
                    >
                      Delete Checked Row
                    </Button>

                    <Button
                      href="#pablo"
                      style={{
                        backgroundColor: "green",
                      }}
                      size="sm"
                    >
                      <CSVLink
                        {...csvReport}
                        style={{
                          color: "white",
                        }}
                      >
                        Download CSV
                      </CSVLink>
                    </Button>
                    <Dropdown
                      className="d-inline-block"
                      isOpen={dropdownOpen}
                      toggle={toggle}
                    >
                      <DropdownToggle
                        caret
                        color="default"
                        data-toggle="dropdown"
                        id="dropdownMenuButton"
                        size="sm"
                      >
                        Filter
                      </DropdownToggle>
                      <DropdownMenu right aria-labelledby="dropdownMenuButton">
                        <DropdownItem onClick={() => fetchApplications()}>
                          All
                        </DropdownItem>
                        {/*mapping from applicatin array only unique values*/}
                        {[
                          ...new Set(
                            filterApplication.map((item) => item.jobProfile)
                          ),
                        ].map((item) => (
                          <DropdownItem
                            key={item}
                            onClick={() => filterJobProfile(item)}
                          >
                            {item}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Job ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Job Profile</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Experience</th>
                    <th scope="col">Resume</th>
                    <th scope="col" />
                  </tr>
                </thead>
                {applications.length > 0 ? (
                  <tbody>
                    {applications.map((application) => (
                      <tr>
                        <td>
                          <input
                            type="checkbox"
                            style={{
                              width: "px",
                              height: "20px",
                            }}
                            id="CheckAllZ"
                            onChange={(e) => {
                              if (e.target.checked) {
                                arrayForDeletion.push(application._id);
                              } else {
                                arrayForDeletion.splice(
                                  arrayForDeletion.indexOf(application._id),
                                  1
                                );
                              }
                            }}
                          />
                        </td>
                        <td>{application.applyJobId}</td>

                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {application.name}
                              </span>
                            </Media>
                          </Media>
                        </th>

                        {/*Article Category*/}
                        {/* <td>{application.applyJobId}</td> */}
                        <td>{application.jobProfile}</td>
                        <td>{application.email}</td>
                        <td>{application.phoneNumber}</td>
                        <td>{application.experience}</td>

                        <td>
                          <a href={`${application.file}`}>Link</a>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-dark"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <BiDotsVertical />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => {
                                  handleDelete(application._id);
                                }}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>

                        {/*Article Options*/}
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="8">No applications Found.</td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {/* <Button
                className="primary"
                style={{
                  backgroundColor: "#5E72E4",
                  color: "white",
                  marginTop: "20px",
                }}
                onClick={applications.length > 0 ? deleteAll : noDataToDelete}
              >
                Delete All
              </Button> */}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default JobApplications;
