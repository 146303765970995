/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import blogIndex from "views/blogs/index.js";
import BlogCreate from "views/blogs/create.js";
import BlogEdit from "views/blogs/edit";
import JobCreate from "views/jobs/create";
import JobIndex from "views/jobs/index";
import ContactForm from "views/ContactForms/ContactForm";
import H1BForm from "views/H1BForm/h1bForm";
import JobApplications from "views/jobs/JobApplications";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin",
  // },
  {
    path: "/articles",
    name: "Articles",
    icon: "ni ni-chat-round text-info",
    component: blogIndex,
    layout: "/admin",
  },
  {
    path: "/jobs",
    name: "Jobs",
    icon: "ni ni-chat-round text-info",
    component: JobIndex,
    layout: "/admin",
  },
  {
    path: "/jobApplication",
    name: "Job Application",
    icon: "ni ni-chat-round text-info",
    component: JobApplications,
    layout: "/admin",
  },

  {
    path: "/contactForm",
    name: "Contact Data",
    icon: "ni ni-chat-round text-info",
    component: ContactForm,
    layout: "/admin",
  },

  {
    path: "/h1b",
    name: "H1B Application",
    icon: "ni ni-chat-round text-info",
    component: H1BForm,
    layout: "/admin",
  },

  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },

  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin",
  // },
  {
    path: "/login",
    name: "Logout",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },

  // {
  //   path: "/signup",
  //   component: Register,
  //   layout: "/auth",
  // },
];
export default routes;
