/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import Swal from "sweetalert2";

import {
  Badge,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Button,
  Col,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  UncontrolledTooltip,
  Dropdown,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { BiDotsVertical } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
  createFromBlockArray,
} from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import uuid from "uuid";
import axios from "axios";

// import { createObjectLiteral } from "typescript";
// import TextEditor from "./TextEditor";

// core components

const BlogIndex = () => {
  //array for articles ids to be deleted
  var arrayForDeletion = [];

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorState2, setEditorState2] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  const onEditorStateChange2 = (editorState) => {
    setEditorState2(editorState);
  };

  const [textValue, setTextValue] = useState(null);

  //toggle for switching between view and create blog
  const [toggle, setToggle] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
  };

  //toggle for dropdown to open
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const [data, setData] = useState({
    blogHeader: "",
    blogDetails: "",
    data: textValue,
    category: "",
    file: "",
  });

  const [imageUploads, setImageUploads] = useState("");

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("blogHeader", data.blogHeader);
    formData.append("blogDetails", data.blogDetails);
    formData.append("data", data.data);
    formData.append("category", data.category);
    formData.append("file", data.file);

    axios
      .post("https://backend.tror.ai/api/article/new", formData)
      .then((res) => {
        Swal.fire({
          title: "Success",
          type: "success",
          text: "Article has been created successfully",
        }).then((result) => {
          if (result.value) {
            fetchArticles();
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });

    setData({
      blogHeader: "",
      blogDetails: "",
      blogPara: ["", "", "", "", ""],
      category: "",
    });
  };

  const noDataToDelete = () => {
    Swal.fire({
      title: "No data to delete",
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
    });
  };

  //fetching api data
  const [articles, setArticles] = useState([]);
  const [newData, setNewData] = useState([]);
  const fetchArticles = () => {
    axios
      .get("https://backend.tror.ai/api/allhtmlArticles")
      .then((res) => {
        setArticles(res.data.articles);
        setNewData(res.data.articles);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchArticles();
  }, [updateData]);

  //update article
  const [updateData, setUpdateData] = useState([]);

  const handleUpdate = (id) => {
    axios
      .get(`https://backend.tror.ai/api/article/${id}`)
      .then((res) => {
        setUpdateData(res.data.article);

        const html = res.data.article.HTMLfile;
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorStateUpdate = EditorState.createWithContent(contentState);
          setEditorState2(editorStateUpdate);
          setTextValue(html);
          console.log(html, "html");
        }

        //call back function to set editor state
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(updateData, "updateData");

  const handleUpdateOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("blogHeader", updateData.blogHeader);
    formData.append("blogDetails", updateData.blogDetails);
    let i = 0;
    updateData.blogPara.forEach((element) => {
      if (element) {
        formData.append(`blogPara[${i}]`, element);
        i++;
      }
    });
    formData.append("category", updateData.category);

    formData.append("file", updateData.file);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    axios
      .post(
        `https://backend.tror.ai/api/article/update/${updateData._id}`,
        formData
      )
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: "Success",
          type: "success",
          text: "Article has been updated successfully",
        }).then((result) => {
          if (result.value) {
            fetchArticles();
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
    setToggleEdit(!toggleEdit);
    setUpdateData({
      ...updateData,
      blogHeader: "",
      blogDetails: "",
      blogPara: ["", "", "", "", ""],
      category: "",
    });
  };

  //delete article

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`https://backend.tror.ai/api/article/${id}`)
          .then((res) => {
            Swal.fire({
              title: "Deleted!",
              text: "Your article has been deleted.",
              type: "success",
            }).then((result) => {
              if (result.value) {
                fetchArticles();
              }
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const deleteAllArticles = () => {
    Swal.fire({
      title: "Are you sure to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        axios
          .delete("https://backend.tror.ai/api/deleteallArticle")
          .then((res) => {
            Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
            }).then((result) => {
              if (result.value) {
                fetchArticles();
              }
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  return (
    <>
      <Header />

      {toggle ? (
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Add New Article</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        // href="/blogs"
                        onClick={(e) => {
                          handleToggle(e);
                          fetchArticles();
                        }}
                        size="sm"
                      >
                        Back to list
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form id="form">
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="5">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Title
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="Title"
                              name="blogHeader"
                              value={data.blogHeader}
                              type="text"
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    blogHeader: e.target.value,
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Category
                            </label>
                            <Dropdown
                              name="category"
                              style={{
                                width: "100%",
                              }}
                              isOpen={dropdownOpen}
                              toggle={toggleDropDown}
                            >
                              <DropdownToggle caret>
                                {data.category == ""
                                  ? "Select Category"
                                  : data.category}
                              </DropdownToggle>
                              <DropdownMenu
                                style={
                                  {
                                    // minWidth: "40%",
                                  }
                                }
                              >
                                <DropdownItem>
                                  <div
                                    onClick={(e) => {
                                      setData((prevState) => {
                                        return {
                                          ...prevState,
                                          category: "Blog",
                                        };
                                      });
                                    }}
                                  >
                                    Blogs
                                  </div>
                                </DropdownItem>
                                <DropdownItem>
                                  <div
                                    onClick={(e) => {
                                      setData((prevState) => {
                                        return {
                                          ...prevState,
                                          category: "News & Updates",
                                        };
                                      });
                                    }}
                                  >
                                    News & Updates
                                  </div>
                                </DropdownItem>
                                <DropdownItem>
                                  <div
                                    onClick={(e) => {
                                      setData((prevState) => {
                                        return {
                                          ...prevState,
                                          category: "Case Studies",
                                        };
                                      });
                                    }}
                                  >
                                    Case Studies
                                  </div>
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </FormGroup>
                        </Col>
                        <Col lg="5">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Content Highlight
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="Title"
                              value={data.blogDetails}
                              name="blogDetails"
                              type="text"
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    blogDetails: e.target.value,
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        {/*
                        <Col lg="6">
                          <FormGroup>
                            <label>Para 1</label>

                            <Input
                              className="form-control-alternative"
                              placeholder="write your first para here.."
                              rows="3"
                              value={data.blogPara[0]}
                              name="blogPara[0]"
                              type="textarea"
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    blogPara: [e.target.value],
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label>Para 2</label>

                            <Input
                              className="form-control-alternative"
                              placeholder="If you want to add one more para - write your second para here.."
                              rows="3"
                              value={data.blogPara[1]}
                              name="blogPara[1]"
                              type="textarea"
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    blogPara: [
                                      data.blogPara[0],
                                      e.target.value,
                                    ],
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label>Para 3</label>

                            <Input
                              className="form-control-alternative"
                              placeholder="If you want to add one more para - write your third para here.."
                              rows="3"
                              value={data.blogPara[2]}
                              name="blogPara[2]"
                              type="textarea"
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    blogPara: [
                                      data.blogPara[0],
                                      data.blogPara[1],
                                      e.target.value,
                                    ],
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        {/*button to add more para* 
                        <Col lg="6">
                          <FormGroup>
                            <label>Para 4</label>

                            <Input
                              className="form-control-alternative"
                              placeholder="If you want to add one more para - write your fourth para here.."
                              rows="3"
                              value={data.blogPara[3]}
                              name="blogPara[3]"
                              type="textarea"
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    blogPara: [
                                      data.blogPara[0],
                                      data.blogPara[1],
                                      data.blogPara[2],
                                      e.target.value,
                                    ],
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label>Para 5</label>

                            <Input
                              className="form-control-alternative"
                              placeholder="If you want to add one more para - write your fifth para here.."
                              rows="3"
                              name="blogPara[4]"
                              value={data.blogPara[4]}
                              type="textarea"
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    blogPara: [
                                      data.blogPara[0],
                                      data.blogPara[1],
                                      data.blogPara[2],
                                      data.blogPara[3],
                                      e.target.value,
                                    ],
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        */}
                        <Col lg="6">
                          <FormGroup>
                            <label>Article Header Image</label>
                            <Input
                              className="form-control"
                              id="File"
                              type="file"
                              name="file"
                              onChange={(e) => {
                                const formData = new FormData();
                                formData.append("file", e.target.files[0]);
                                axios
                                  .post(
                                    "https://backend.tror.ai/api/uploadImageForArticle",
                                    formData
                                  )
                                  .then((res) => {
                                    console.log(res);

                                    if (res.data.success) {
                                      console.log(res);
                                      setData({
                                        ...data,
                                        file: res.data.link,
                                      });
                                    }
                                  });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label>Image Link Generator</label>
                            {imageUploads.length > 0 ? (
                              <div>
                                {" "}
                                <input
                                  type="text"
                                  style={{
                                    disabled: true,
                                  }}
                                  value={imageUploads}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard
                                      .writeText(imageUploads)
                                      .then(() => {
                                        //alert copied
                                        Swal.fire({
                                          position: "center",
                                          icon: "success",
                                          title: "Copied to clipboard",
                                          showConfirmButton: false,
                                          timer: 1500,
                                        });
                                      });
                                  }}
                                />
                                <button
                                  style={{
                                    marginLeft: "10px",
                                    backgroundColor: "green",
                                    color: "white",
                                    border: "none",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard
                                      .writeText(imageUploads)
                                      .then(() => {
                                        //alert copied
                                        Swal.fire({
                                          position: "center",
                                          icon: "success",
                                          title: "Copied to clipboard",
                                          showConfirmButton: false,
                                          timer: 1500,
                                        });
                                      });
                                  }}
                                >
                                  Copy
                                </button>
                                <button
                                  style={{
                                    marginLeft: "10px",
                                    backgroundColor: "blue",
                                    color: "white",
                                    border: "none",
                                  }}
                                  onClick={(e) => {
                                    setImageUploads("");
                                  }}
                                >
                                  Add new
                                </button>
                              </div>
                            ) : (
                              <Input
                                className="form-control"
                                id="File"
                                type="file"
                                name="file"
                                onChange={(e) => {
                                  const formData = new FormData();
                                  formData.append("file", e.target.files[0]);
                                  axios
                                    .post(
                                      "https://backend.tror.ai/api/uploadImageForArticle",
                                      formData
                                    )
                                    .then((res) => {
                                      console.log(res);
                                      if (res.data.success) {
                                        console.log(res);
                                        setImageUploads(res.data.link);
                                      }
                                    });
                                }}
                              />
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label>Text Area</label>
                            <div>
                              <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={onEditorStateChange}
                                toolbar={{
                                  list: { inDropdown: true },
                                  textAlign: { inDropdown: true },
                                }}
                                style={{
                                  border: "1px solid black",
                                  borderRadius: "5px",
                                }}
                                onChange={() => {
                                  setTextValue(
                                    draftToHtml(
                                      convertToRaw(
                                        editorState.getCurrentContent()
                                      )
                                    )
                                  );
                                  console.log(textValue, "value value");
                                }}
                              />
                              <textarea
                                style={{
                                  resize: "both",
                                  display: "none",
                                }}
                                disabled
                                value={draftToHtml(
                                  convertToRaw(editorState.getCurrentContent())
                                )}
                              ></textarea>
                              <div className="text-right">
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    console.log("onclicked");
                                    // axios
                                    //   .post("https://localhost:5000/api/article/saveFile", {
                                    //     data: textValue,
                                    //   })
                                    //   .then((res) => {
                                    //     console.log(res.data);
                                    //   })
                                    //   .catch((err) => {
                                    //     console.log(err);
                                    //   });
                                    fetch(
                                      "https://backend.tror.ai/api/article/saveFile",
                                      {
                                        method: "POST",
                                        headers: {
                                          "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({
                                          dataHTML: textValue,
                                          blogHeader: data.blogHeader,
                                          blogDetails: data.blogDetails,
                                          category: data.category,
                                          file: data.file,
                                        }),
                                      }
                                    ).then((res) => {
                                      Swal.fire({
                                        title: "Success",
                                        text: "Article Saved",
                                        icon: "success",
                                      }).then((res) => {
                                        setData({
                                          blogHeader: "",
                                          blogDetails: "",
                                          category: "",
                                          file: "",
                                        });
                                        setImageUploads("");
                                        document.getElementById("File").value =
                                          null;

                                        setEditorState(
                                          EditorState.createEmpty()
                                        );
                                        // setToggleEdit(!toggleEdit);
                                      });
                                    });
                                  }}
                                >
                                  Save
                                </Button>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : toggleEdit ? (
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Edit Article</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        // href="/blogs"
                        onClick={(e) => {
                          setToggleEdit(!toggleEdit);
                        }}
                        size="sm"
                      >
                        Back to list
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form id="form">
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="5">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Title
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="Title"
                              name="blogHeader"
                              value={updateData.blogHeader}
                              type="text"
                              onChange={(e) => {
                                setUpdateData((prevState) => {
                                  return {
                                    ...prevState,
                                    blogHeader: e.target.value,
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Category
                            </label>
                            <Dropdown
                              name="category"
                              style={{
                                width: "100%",
                              }}
                              isOpen={dropdownOpen}
                              toggle={toggleDropDown}
                            >
                              <DropdownToggle caret>
                                {updateData.category == ""
                                  ? "Select Category"
                                  : updateData.category}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem>
                                  <div
                                    onClick={(e) => {
                                      setUpdateData((prevState) => {
                                        return {
                                          ...prevState,
                                          category: "Blog",
                                        };
                                      });
                                    }}
                                  >
                                    Blogs
                                  </div>
                                </DropdownItem>
                                <DropdownItem>
                                  <div
                                    onClick={(e) => {
                                      setUpdateData((prevState) => {
                                        return {
                                          ...prevState,
                                          category: "News & Updates",
                                        };
                                      });
                                    }}
                                  >
                                    News & Updates
                                  </div>
                                </DropdownItem>
                                <DropdownItem>
                                  <div
                                    onClick={(e) => {
                                      setUpdateData((prevState) => {
                                        return {
                                          ...prevState,
                                          category: "Case Studies",
                                        };
                                      });
                                    }}
                                  >
                                    Case Studies
                                  </div>
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </FormGroup>
                        </Col>
                        <Col lg="5">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Content Highlight
                            </label>
                            <Input
                              className="form-control-alternative"
                              // id="input-title"

                              value={updateData.blogDetails}
                              name="blogDetails"
                              type="text"
                              placeholder="Content highlight"
                              onChange={(e) => {
                                setUpdateData((prevState) => {
                                  return {
                                    ...prevState,
                                    blogDetails: e.target.value,
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        {/*
                        <Col lg="6">
                          <FormGroup>
                            <label>Para 1</label>

                            <Input
                              className="form-control-alternative"
                              placeholder="write your first para here.."
                              rows="3"
                              value={data.blogPara[0]}
                              name="blogPara[0]"
                              type="textarea"
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    blogPara: [e.target.value],
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label>Para 2</label>

                            <Input
                              className="form-control-alternative"
                              placeholder="If you want to add one more para - write your second para here.."
                              rows="3"
                              value={data.blogPara[1]}
                              name="blogPara[1]"
                              type="textarea"
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    blogPara: [
                                      data.blogPara[0],
                                      e.target.value,
                                    ],
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label>Para 3</label>

                            <Input
                              className="form-control-alternative"
                              placeholder="If you want to add one more para - write your third para here.."
                              rows="3"
                              value={data.blogPara[2]}
                              name="blogPara[2]"
                              type="textarea"
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    blogPara: [
                                      data.blogPara[0],
                                      data.blogPara[1],
                                      e.target.value,
                                    ],
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        {/*button to add more para* 
                        <Col lg="6">
                          <FormGroup>
                            <label>Para 4</label>

                            <Input
                              className="form-control-alternative"
                              placeholder="If you want to add one more para - write your fourth para here.."
                              rows="3"
                              value={data.blogPara[3]}
                              name="blogPara[3]"
                              type="textarea"
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    blogPara: [
                                      data.blogPara[0],
                                      data.blogPara[1],
                                      data.blogPara[2],
                                      e.target.value,
                                    ],
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label>Para 5</label>

                            <Input
                              className="form-control-alternative"
                              placeholder="If you want to add one more para - write your fifth para here.."
                              rows="3"
                              name="blogPara[4]"
                              value={data.blogPara[4]}
                              type="textarea"
                              onChange={(e) => {
                                setData((prevState) => {
                                  return {
                                    ...prevState,
                                    blogPara: [
                                      data.blogPara[0],
                                      data.blogPara[1],
                                      data.blogPara[2],
                                      data.blogPara[3],
                                      e.target.value,
                                    ],
                                  };
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        */}
                        <Col lg="7">
                          <FormGroup>
                            <label>Display Article Image :</label>
                            <img
                              src={updateData.file}
                              alt="image"
                              style={{
                                width: "200px",
                                height: "100px",
                                marginLeft: "20px",
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label>Update Article Header Image</label>
                            <Input
                              className="form-control"
                              id="File2"
                              type="file"
                              name="file"
                              onChange={(e) => {
                                const formData = new FormData();
                                formData.append("file", e.target.files[0]);
                                axios
                                  .post(
                                    "https://backend.tror.ai/api/uploadImageForArticle",
                                    formData
                                  )
                                  .then((res) => {
                                    console.log(res);

                                    if (res.data.success) {
                                      console.log(res);
                                      setUpdateData({
                                        ...updateData,
                                        file: res.data.link,
                                      });
                                    }
                                  });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label>Image Link Generator</label>
                            {imageUploads.length > 0 ? (
                              <div>
                                {" "}
                                <input
                                  type="text"
                                  disabled
                                  value={imageUploads}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(imageUploads);
                                  }}
                                />
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(imageUploads);
                                  }}
                                >
                                  Copy
                                </button>
                                <button
                                  onClick={(e) => {
                                    setImageUploads("");
                                  }}
                                >
                                  Add new
                                </button>
                              </div>
                            ) : (
                              <Input
                                className="form-control"
                                id="File"
                                type="file"
                                name="file"
                                onChange={(e) => {
                                  const formData = new FormData();
                                  formData.append("file", e.target.files[0]);
                                  axios
                                    .post(
                                      "https://backend.tror.ai/api/uploadImageForArticle",
                                      formData
                                    )
                                    .then((res) => {
                                      console.log(res);
                                      if (res.data.success) {
                                        console.log(res);
                                        setImageUploads(res.data.link);
                                      }
                                    });
                                }}
                              />
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label>Text Area</label>
                            <div>
                              <Editor
                                editorState={editorState2}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={onEditorStateChange2}
                                //set editor state

                                toolbar={{
                                  list: { inDropdown: true },
                                  textAlign: { inDropdown: true },
                                }}
                                style={{
                                  border: "1px solid black",
                                  borderRadius: "5px",
                                }}
                                onChange={() => {
                                  setTextValue(
                                    draftToHtml(
                                      convertToRaw(
                                        editorState2.getCurrentContent()
                                      )
                                    )
                                  );
                                  console.log(textValue, "value value");
                                }}
                              />
                              <textarea
                                style={{
                                  resize: "both",
                                  display: "none",
                                }}
                                disabled
                                value={draftToHtml(
                                  convertToRaw(editorState2.getCurrentContent())
                                )}
                              ></textarea>
                              <div className="text-right">
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    axios
                                      .put(
                                        `https://backend.tror.ai/api/article/updateFile/${updateData._id}`,
                                        {
                                          dataHTML: textValue,
                                          blogHeader: updateData.blogHeader,
                                          blogDetails: updateData.blogDetails,
                                          category: updateData.category,
                                          file: updateData.file,
                                        }
                                      )
                                      .then((res) => {
                                        Swal.fire({
                                          title: "Success",
                                          text: "Article Updated Successfully",
                                          icon: "success",
                                        }).then(() => {
                                          fetchArticles();
                                        });
                                      });
                                  }}
                                >
                                  Save Changes
                                </Button>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="5">
                      <h3 className="mb-0">Articles</h3>
                    </Col>
                    {/*Search bar*/}
                    <Col className="text-center" xs="2">
                      <Input
                        type="text"
                        placeholder="Search..."
                        style={{
                          height: "28px",
                          width: "300px",
                          position: "relative",
                          right: "35%",
                        }}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setArticles(newData);
                          } else {
                            const search = e.target.value;

                            const filtered = newData.filter((article) => {
                              return (
                                article.blogHeader
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ||
                                article.category
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ||
                                article.blogDetails
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              );
                            });

                            setArticles(filtered);
                          }
                        }}
                      />
                    </Col>
                    {/* delete button to delete multiple checked box row */}

                    <Col className="text-right" xs="5">
                      <Button
                        href="#pablo"
                        id="deleteCheckedItem"
                        style={{
                          backgroundColor: "#ff0000",
                          color: "#fff",
                        }}
                        size="sm"
                        onClick={() => {
                          console.log("AbC");
                          // fetch(
                          //   "https://backend.tror.ai/api/deleteMultipleArticles",
                          //   {
                          //     method: "DELETE",
                          //     headers: {
                          //       "Content-Type": "application/json",
                          //     },
                          //     body: JSON.stringify({
                          //       articleids: arrayForDeletion,
                          //     }),
                          //   }
                          // ).then((res) => {
                            Swal.fire({
                              title: `${arrayForDeletion.length === 0 ? "No item selected" : "Are you sure?"}`,
                              text: `${arrayForDeletion.length === 0 ? "Please select any one item before deleting" : "You won't be able to revert this!"}`,
                              type: "warning",
                              showCancelButton: true,
                            }).then((result) => {
                              if(result.value){
                                fetch(
                                    "https://backend.tror.ai/api/deleteMultipleArticles",
                                    {
                                      method: "DELETE",
                                      headers: {
                                        "Content-Type": "application/json",
                                      },
                                      body: JSON.stringify({
                                        articleids: arrayForDeletion,
                                      }),
                                    }
                                )
                              fetchArticles();
                              }
                              document
                                .querySelectorAll("input[type=checkbox]")
                                .forEach((element) => {
                                  element.checked = false;
                                });

                              arrayForDeletion = [];
                              fetchArticles();
                            });
                          // });
                        }}
                      >
                        Delete Checked Row
                      </Button>
                      <Button
                        color="primary"
                        onClick={(e) => {
                          handleToggle(e);
                        }}
                        size="sm"
                      >
                        Add New
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Title</th>
                      <th scope="col">Category</th>
                      <th scope="col">Content Highlight</th>
                      <th scope="col">Image</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  {articles.length > 0 ? (
                    <tbody>
                      {articles.map((article) => (
                        <tr>
                          {/*Adding a checkbox */}
                          <td>
                            <input
                              type="checkbox"
                              // className="custom-control-input"
                              style={{
                                width: "px",
                                height: "20px",
                              }}
                              id="CheckAllZ"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  // "background-color: red, color: #fff, position: relative, left: 60%";
                                  arrayForDeletion.push(article._id);
                                } else {
                                  arrayForDeletion.splice(
                                    arrayForDeletion.indexOf(article._id),
                                    1
                                  );

                                  console.log(arrayForDeletion, "array");
                                }
                              }}
                            />
                          </td>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {article.blogHeader}
                                </span>
                              </Media>
                            </Media>
                          </th>

                          {/*Article Category*/}

                          <td>{article.category}</td>
                          <td className="text-truncate" style={{maxWidth:"500px"}}>{article.blogDetails}</td>

                          <td>
                            <img
                              src={article.file}
                              alt="article"
                              style={{
                                height: "100px",
                                width: "200px",
                              }}
                            />
                          </td>

                          {/*Article Options*/}
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-dark"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <BiDotsVertical />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => {
                                    handleUpdate(article._id);
                                    setToggleEdit(!toggleEdit);
                                  }}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => {
                                    handleDelete(article._id);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="8">No Articles Found.</td>
                      </tr>
                    </tbody>
                  )}
                </Table>
                {/* <Button
                  className="primary"
                  style={{
                    backgroundColor: "#5E72E4",
                    color: "white",
                    marginTop: "20px",
                  }}
                  onClick={
                    articles.length > 0 ? deleteAllArticles : noDataToDelete
                  }
                >
                  Delete All
                </Button> */}
              </Card>
            </div>
          </Row>
        </Container>
      )}
    </>
  );
};

export default BlogIndex;
